import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { LoggingService } from '@logging/logging.service';

@Component({
  selector: 'app-sales-popup',
  templateUrl: './sales-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SalesPopupComponent {
  visible =
    this.activeSecurity.hasOrganisationAuthorityTo('HandoverSubmitPayroll') ||
    this.activeSecurity.hasAnyGroupAuthorityTo('HandoverSubmitGroup');

  logger = inject(LoggingService);

  constructor(private activeSecurity: ActiveSecurityContextStateService) {
    this.logger.trace('SalesPopupComponent visible? ' + this.visible);
  }
}
