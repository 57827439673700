import { ChangeDetectionStrategy, Component, Injectable } from '@angular/core';
import { ChatConversationService } from '@design/chat-bot/chat-conversation.service';
import { ChatBotDataService } from '@design/chat-bot/chat-bot-data.service';
import { PayrollsChatbotService } from '@app/payrolls/payroll-chat-bot/payrolls-chat-bot.service';

@Component({
  selector: 'app-payroll-chat-bot',
  templateUrl: './payroll-chat-bot.component.html',
  styleUrl: './payroll-chat-bot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PayrollChatBotComponent {}
