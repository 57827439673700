import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractEntityGridColumnComponent } from '@entity-framework/entity-grid/state/abstract-entity-grid-column.component';
import { BureauPaymentListItem } from '@app/bureau/payments/models/bureau-payment-list-item';

@Component({
  selector: 'app-bureau-payments-status-column',
  template: `
    <div class="row">
      <div class="col">
        <label [class]="'status-item status-' + this.listItem.status?.toLowerCase()">{{
          listItem.status === 'NotAuthorised' ? 'Not authorised' : listItem.status
        }}</label>
      </div>
    </div>
  `,
  styleUrl: '../../../bank-files/entity-grid/bureau-bank-file-status/bureau-bank-file-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BureauPaymentsStatusColumnComponent extends AbstractEntityGridColumnComponent<BureauPaymentListItem> {}
