import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AdvancedReportConfig } from '@app/advanced-reports/models/advanced-report-config';
import { of } from 'rxjs';
import { getBureauPaymentsGridColumns } from '@app/bureau/payments/entity-grid/columns/bureau-payments-grid-columns';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { BureauPaymentsSearchDataProvider } from '@app/bureau/payments/entity-grid/data-provider/bureau-payments-search.data-provider';
import { BureauPaymentsSearchRequest } from '@app/bureau/payments/entity-grid/data-provider/bureau-payments-search-request';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, take } from 'rxjs/operators';
import { SearchResponse } from '@entity-framework/entity-search/search-response';

@UntilDestroy()
@Component({
  template: '<app-advanced-reports [config]="config" [rowData]="rowData$ | async"></app-advanced-reports>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BureauPaymentsSearchDataProvider],
  standalone: false
})
export class BureauPaymentsAdvancedViewComponent {
  rowData$ = this.dataProvider
    .search$({
      pageSize: -1,
      pageIndex: 0
    } as BureauPaymentsSearchRequest)
    .pipe(
      take(1),
      untilDestroyed(this),
      map((response: SearchResponse<any>) => {
        return response.results;
      })
    );

  config: AdvancedReportConfig = {
    gridId: 'bureau-payments',
    reportTitle: 'Bureau Payments',
    reportType: 'standard',
    gridColumns$: of(getBureauPaymentsGridColumns(this.activeSecurityContext.activeBureau, true))
  };

  constructor(
    private activeSecurityContext: ActiveSecurityContextStateService,
    private dataProvider: BureauPaymentsSearchDataProvider
  ) {}
}
