import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppLayoutStateService } from '../../app-layout.state-service';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { BurgerBarService } from '../../burger-bar/burger-bar.service';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';
import { CssBreakpointsService } from '@design/layout/css-breakpoints.service';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';

@Component({
  selector: 'app-cintra-product-navigation',
  templateUrl: './cintra-product-navigation.component.html',
  styleUrls: ['./cintra-product-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CintraProductNavigationComponent {
  menuIcon$ = this.appLayout.burgerBar$.pipe(map((bb) => (bb.visible ? 'close' : 'menu')));
  cindaEnabled$ = this.featureFlagService.getValue$('cinda', false);
  isPayrollsWithAccess$ = this.activeCintraProductService.activeProduct$.pipe(
    map((product) => {
      return product === 'Payroll' && this.activeSecurity.hasOrganisationAuthorityTo('AccessPayrolls');
    })
  );

  constructor(
    public appLayout: AppLayoutStateService,
    public activeSecurity: ActiveSecurityContextStateService,
    public burgerBarService: BurgerBarService,
    public activeCintraProductService: ActiveCintraProductService,
    public cssBreakpointsService: CssBreakpointsService,
    @Inject(FeatureFlagsServiceInjectionToken) private featureFlagService: FeatureFlagService
  ) {}
}
