export enum BankFileStatusType {
  NEW = 'New',
  DOWNLOADED = 'Downloaded',
  TRANSMITTED = 'Transmitted',
  SUBMITTED = 'Submitted',
  CANCELLED = 'Cancelled',
  ADDED_TO_BATCH = 'AddedToBatch',
  REMOVED_FROM_BATCH = 'RemovedFromBatch'
}

export enum BankFileStatusFilterType {
  NEW = 'New',
  DOWNLOADED = 'Downloaded',
  TRANSMITTED = 'Transmitted',
  SUBMITTED = 'Submitted',
  ALL = 'All'
}

export enum BankFileStatusRequestType {
  NEW = 0,
  DOWNLOADED = 1,
  SUBMITTED = 2,
  CANCELLED = 3,
  TRANSMITTED = 4
}

export enum BankFileTypeFilterType {
  SALARY = 'Salary',
  HMRC = 'HMRC',
  THIRD_PARTY = 'Third Party',
  ALL = 'All'
}

export enum BankFileResultType {
  BankFile = 'BankFile',
  Batch = 'Batch'
}
