import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BeamerService } from './beamer.service';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { AppLayoutStateService } from '../app-layout.state-service';
import { CssBreakpointsService } from '@design/layout/css-breakpoints.service';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';
import { CintraProduct, getProductHelpUrl } from '@app/cintra-product';
import { map } from 'rxjs/operators';
import { organisationRoutes } from '@security/organisations/organisation-routes';
import { getAdminHubLandingRoute } from '@app/admin-hub/admin-hub-routes';

/**
 * Static app header. Static in that it's a permanent layout fixture, but its content is dynamic:
 * 1. Access to the {@link BurgerBarComponent}
 * 2. User my-profile "My Account" menu populated on sign-in
 * Shares models via {@link AppLayoutStateService}. Embedded in {@link AppLayoutComponent}
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BeamerService],
  standalone: false
})
export class HeaderComponent implements OnInit {
  canAccessEmployeeHub = this.activeSecurity.canAccessEmployeeHub();
  canAccessCintraPayrolls = this.activeSecurity.canAccessCintraPayrolls();
  canAccessAdminHub = this.activeSecurity.canAccessAdminHub();
  canAccessBureau = this.activeSecurity.hasApplicationAuthorityTo('EditBureaux') || this.activeSecurity.hasBureauProfilePermissions();
  elementIdForBeamer = BeamerService.elementIdForBeamer;

  fullName$ = this.activeSecurity.activeUser$.pipe(map((info) => `${info.forename} ${info.surname}`));
  isImpersonated = () => this.activeSecurity.isImpersonated();
  endUserImpersonation = () => this.activeSecurity.endUserImpersonation();

  constructor(
    public activeSecurity: ActiveSecurityContextStateService,
    public beamerService: BeamerService,
    private router: Router,
    public activeCintraProductService: ActiveCintraProductService,
    public cssBreakpointsService: CssBreakpointsService,
    public appLayout: AppLayoutStateService
  ) {}

  ngOnInit() {
    this.beamerService.initialise();
  }

  navigateProduct = (cintraProduct: CintraProduct) => {
    ActiveCintraProductService.cachedActiveProduct = cintraProduct;

    if (cintraProduct === 'EmployeeHub') {
      this.router.navigateByUrl('/employee-hub/dashboard').then();
    } else if (cintraProduct == 'AdminHub') {
      this.router.navigateByUrl(getAdminHubLandingRoute(this.activeSecurity)).then();
    } else if (cintraProduct === 'Bureau') {
      this.router.navigateByUrl(this.activeSecurity.bureauHomeRoute()).then();
    } else {
      if (this.activeSecurity.hasApplicationAuthorityTo('EditOrganisations')) {
        this.router.navigateByUrl(`/${organisationRoutes.hub}`).then();
      } else {
        this.activeSecurity.navigatePayrolls();
      }
    }
  };

  home = () => this.activeSecurity.navigateHome();
  protected readonly getProductHelpUrl = getProductHelpUrl;
}
