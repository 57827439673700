import { ChangeDetectionStrategy, Component } from '@angular/core';
import { formatDate } from '@utils/date-utils';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';

@Component({
  selector: 'app-account-expiring-banner',
  templateUrl: './account-expiring-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AccountExpiringBannerComponent {
  expiryDate = formatDate(this.activeSecurity.activeEmployment.maxLoginDate);

  constructor(private activeSecurity: ActiveSecurityContextStateService) {}
}
