import { Component } from '@angular/core';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { Member, MyEmploymentModel } from '@security/users/models/member';
import { DateTime } from 'luxon';

interface OrganisationEmploymentModel {
  organisationName: string;
  myEmploymentModel: MyEmploymentModel;
}

/**
 * Material menu which presents the list of Employments the user can select to work with.
 * Embedded in the {@link HeaderComponent}.
 */
@Component({
  selector: 'app-employment-menu',
  templateUrl: './employment-menu.component.html',
  styleUrls: ['../_context-menu.scss', 'employment-menu.component.scss'],
  standalone: false
})
export class EmploymentMenuComponent {
  employments = this.employmentsToList(this.activeSecurity.memberships);
  activeMembership = this.activeSecurity.activeMembership;
  activeEmployment = this.activeSecurity.activeEmployment;
  hasMultiple = this.activeSecurity.employments?.length > 1;

  constructor(private activeSecurity: ActiveSecurityContextStateService) {}

  private employmentsToList(members: Member[]): OrganisationEmploymentModel[] {
    const list: OrganisationEmploymentModel[] = [];
    members.forEach((m) => {
      m.employments.forEach((model) => {
        if (model.accountExpired) return;
        list.push({
          organisationName: m.organisationName,
          myEmploymentModel: model
        });
      });
    });
    return list;
  }

  getEmploymentPeriod = (employment: MyEmploymentModel) => {
    const fromDate = DateTime.fromISO(employment.fromDate).toFormat('dd MMM yyyy');
    const toDate = employment.toDate ? DateTime.fromISO(employment.toDate).toFormat('dd MMM yyyy') : 'now';
    return `${fromDate} - ${toDate}`;
  };

  select = (employment: OrganisationEmploymentModel) => this.activeSecurity.onActiveEmploymentChanged(employment.myEmploymentModel);
}
