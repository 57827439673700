import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { TopNavigationLink } from '@app/app-layout/header/product-navigation/navigation-bar/navigation-bar';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { BehaviorSubject } from 'rxjs';
import { AdminHubNavigationService } from '@app/app-layout/header/product-navigation/admin-hub-navigation/admin-hub-navigation.service';

@Component({
  selector: 'app-admin-hub-navigation',
  templateUrl: './admin-hub-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  standalone: false
})
export class AdminHubNavigationComponent {
  adminHubLinksBehaviorSubject: BehaviorSubject<TopNavigationLink[]> = new BehaviorSubject<TopNavigationLink[]>([]);
  adminHubLinks$ = this.adminHubLinksBehaviorSubject.asObservable();

  constructor(
    public activeSecurity: ActiveSecurityContextStateService,
    public adminHubNavigationService: AdminHubNavigationService,
    @Inject(FeatureFlagsServiceInjectionToken) public featureFlagService: FeatureFlagService
  ) {
    this.createAdminLinks();
  }

  createAdminLinks = () => {
    const adminLinks = this.adminHubNavigationService.fetchAdminHubNavigation();
    this.adminHubLinksBehaviorSubject.next(adminLinks);
  };
}
