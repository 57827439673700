import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { EmployerStatisticsStateService } from '@app/employers/dashboard/state/employer-statistics.state-service';
import { EmployersStateService } from '@app/employers/employer-select/state/employers-state.service';
import { EmployerStatisticsDataProvider } from '@app/employers/dashboard/state/employer-statistics.data-provider';
import { EmployersDataProvider } from '@app/employers/employer-select/state/employers-data.provider';
import { TopNavigationLink } from '@app/app-layout/header/product-navigation/navigation-bar/navigation-bar';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { BehaviorSubject, combineLatest, take } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BureauNavigationService } from '@app/app-layout/header/product-navigation/bureau-navigation/bureau-navigation.service';

@UntilDestroy()
@Component({
  selector: 'app-bureau-navigation',
  templateUrl: './bureau-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EmployerStatisticsDataProvider, EmployersDataProvider, EmployerStatisticsStateService, EmployersStateService],
  standalone: false
})
export class BureauNavigationComponent {
  bureauLinksSubject: BehaviorSubject<TopNavigationLink[]> = new BehaviorSubject<TopNavigationLink[]>([]);
  bureauLinks$ = this.bureauLinksSubject.asObservable();

  constructor(
    public activeSecurity: ActiveSecurityContextStateService,
    @Inject(FeatureFlagsServiceInjectionToken) public featureFlagService: FeatureFlagService,
    public bureauNavigationService: BureauNavigationService
  ) {
    this.createBureauLinks();
  }

  createBureauLinks = () => {
    this.featureFlagService
      .getAllValues$()
      .pipe(take(1))
      .subscribe((featureFlags) => {
        const links = this.bureauNavigationService.fetchBureauNavigation(featureFlags);
        this.bureauLinksSubject.next(links);
      });
  };
}
