import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-cintra-cloud-logo',
  templateUrl: './cintra-cloud-logo.component.html',
  styleUrls: ['./cintra-cloud-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CintraCloudLogoComponent {}
