import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './app-layout.component';
import { BurgerBarComponent } from './burger-bar/burger-bar.component';
import { HeaderComponent } from './header/header.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { BurgerBarListItemComponent } from './burger-bar/burger-bar-list-item/burger-bar-list-item.component';
import { OrganisationMenuComponent } from './header/organisation-menu/organisation-menu.component';
import { SharedModule } from '../shared.module';
import { CintraProductNavigationComponent } from './header/product-navigation/cintra-product-navigation.component';
import { CintraPayNavigationComponent } from './header/product-navigation/cintra-pay-navigation/cintra-pay-navigation.component';
import { EmployeeHubNavigationComponent } from './header/product-navigation/employee-hub-navigation/employee-hub-navigation.component';
import { CintraCloudLogoComponent } from './cintra-cloud-logo/cintra-cloud-logo.component';
import { AuthLayoutComponent } from './auth-n/layout/auth-layout.component';
import { SignInComponent } from './auth-n/sign-in/sign-in.component';
import { AuthenticationComponentsModule } from '@auth-n/authentication-components.module';
import { BannersModule } from '@app/app-layout/banners/banners.module';
import { NavigationBarComponent } from './header/product-navigation/navigation-bar/navigation-bar.component';
import { EquifaxDisclosureAcknowledgementComponent } from '@app/equifax/equifax-disclosure-acknowledgement.component';
import { AdminHubNavigationComponent } from './header/product-navigation/admin-hub-navigation/admin-hub-navigation.component';
import { ResetPasswordComponent } from '@app/app-layout/auth-n/reset-password/reset-password.component';
import { EmploymentMenuComponent } from '@app/app-layout/header/employment-menu/employment-menu.component';
import { SalesModule } from '@app/sales/sales.module';
import { BureauNavigationComponent } from './header/product-navigation/bureau-navigation/bureau-navigation.component';
import { BureauMenuComponent } from '@app/app-layout/header/bureau-menu/bureau-menu.component';
import { MobileTabNavigationComponent } from '@app/app-layout/header/mobile-tab-navigation/mobile-tab-navigation.component';
import { PayrollChatBotModule } from '@app/payrolls/payroll-chat-bot/payroll-chat-bot.module';

@NgModule({
  declarations: [
    AppLayoutComponent,
    BurgerBarComponent,
    BurgerBarListItemComponent,
    NavigationBarComponent,
    CintraProductNavigationComponent,
    CintraPayNavigationComponent,
    EmployeeHubNavigationComponent,
    AdminHubNavigationComponent,
    HeaderComponent,
    OrganisationMenuComponent,
    RightSidebarComponent,
    CintraCloudLogoComponent,
    AuthLayoutComponent,
    SignInComponent,
    ResetPasswordComponent,
    EquifaxDisclosureAcknowledgementComponent,
    EmploymentMenuComponent,
    BureauMenuComponent,
    BureauNavigationComponent,
    MobileTabNavigationComponent
  ],
  imports: [SharedModule, AuthenticationComponentsModule, BannersModule, SalesModule, PayrollChatBotModule],
  exports: [AppLayoutComponent, SignInComponent, AuthLayoutComponent, HeaderComponent]
})
export class AppLayoutModule {}
