import { AbstractFlattenedHierarchyDataset, HierarchyDataSetItem } from '@entity-framework/datasets/abstract-flattened-hierarchy-dataset';

export interface TeamHierarchyDataSetItem extends HierarchyDataSetItem<TeamHierarchyDataSetItem> {}

export class TeamFlattenedHierarchyDataset extends AbstractFlattenedHierarchyDataset<TeamHierarchyDataSetItem, number> {
  getLabel(item: TeamHierarchyDataSetItem): string {
    return item.name;
  }

  getValue(item: TeamHierarchyDataSetItem): number {
    return item.id;
  }

  getFormValue(values: TeamHierarchyDataSetItem[]) {
    return values.map((v) => v.id);
  }

  getInitialItems(formValue: any[]): any {
    return this.items.filter((i) => formValue.includes(i.id));
  }
}
