import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TopNavigationLink } from './navigation-bar';
import { NavigationRoutingService } from '@utils/navigation-routing.service';

@UntilDestroy()
@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NavigationBarComponent {
  @Input() topNavigationLinks: TopNavigationLink[];

  navigated$ = new BehaviorSubject(true);

  constructor(navigationRoutingService: NavigationRoutingService) {
    navigationRoutingService.navigationEvents$.subscribe(() => this.navigated$.next(true));
  }
}
