import { TeamHierarchyDataSetItem } from '@app/bureau/teams/models/teams-hierarchy';

export function convertToHierarchyDataSetItem(data: any[], hierarchyDepth: number = 1): TeamHierarchyDataSetItem[] {
  return data
    .map((item) => {
      const hierarchyItem: TeamHierarchyDataSetItem = {
        id: item.id,
        name: item.name,
        children: convertToHierarchyDataSetItem(item.children, hierarchyDepth + 1),
        hierarchyDepth: hierarchyDepth
      };
      return hierarchyItem;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}
