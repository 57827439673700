import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SlideOutModalStateService } from '@design/modals/slide-out-modal/slide-out-modal-state.service';
import { PayrollChatBotComponent } from '@app/payrolls/payroll-chat-bot/payroll-chat-bot.component';

@Component({
  selector: 'app-minerva-button',
  templateUrl: './minerva-button.component.html',
  styleUrls: ['./minerva-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MinervaButtonComponent implements OnInit, OnDestroy {
  isOpen = false;
  private sub: Subscription;

  constructor(private modalStateService: SlideOutModalStateService) {}

  ngOnInit() {
    this.sub = this.modalStateService.isVisible$.subscribe((visible) => {
      this.isOpen = visible;
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  toggleModal() {
    if (this.isOpen) {
      this.modalStateService.closeModal();
    } else {
      this.modalStateService.openModal(PayrollChatBotComponent);
    }
  }
}
