import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractSearchDataProvider } from '@entity-framework/entity-search/abstract-search.data-provider';
import { LoggingService } from '@logging/logging.service';
import { environment } from '@env/environment';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { BureauPaymentListItem } from '@app/bureau/payments/models/bureau-payment-list-item';
import { BureauPaymentsSearchRequest } from '@app/bureau/payments/entity-grid/data-provider/bureau-payments-search-request';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  BureauPaymentsFilters,
  BureauPaymentsFiltersResponse
} from '@app/bureau/payments/entity-grid/data-provider/bureau-payments-filters-response';
import { convertToHierarchyDataSetItem } from '@app/bureau/teams/edit-modal/state/team-hierarchy-helper';
import { TeamFlattenedHierarchyDataset } from '@app/bureau/teams/models/teams-hierarchy';
import { KeyValuePairDataset } from '@entity-framework/datasets/key-value-pair-dataset';

@Injectable()
export class BureauPaymentsSearchDataProvider extends AbstractSearchDataProvider<BureauPaymentListItem, BureauPaymentsSearchRequest> {
  constructor(
    protected httpClient: HttpClient,
    protected logger: LoggingService,
    private activeSecurity: ActiveSecurityContextStateService
  ) {
    super(httpClient, logger);
    this.useQueryForParameters = true;
  }

  searchEndpoint(): string {
    return `${environment.apiPaths.bureau}/${this.activeSecurity.activeBureau.id}/payments/search`;
  }

  getFilters$(): Observable<BureauPaymentsFilters> {
    return this.httpClient.get(`${environment.apiPaths.bureau}/${this.activeSecurity.activeBureau.id}/payments/filters`).pipe(
      take(1),
      map((response: BureauPaymentsFiltersResponse) => {
        return {
          teams: new TeamFlattenedHierarchyDataset([...convertToHierarchyDataSetItem(response.teams)]),
          paymentTypes: new KeyValuePairDataset<number, string>(response.paymentTypes),
          bankFileStatuses: new KeyValuePairDataset<number, string>(response.bankFileStatuses),
          hmrcSubmissionOptions: new KeyValuePairDataset<number, string>(response.hmrcSubmissionOptions)
        } as BureauPaymentsFilters;
      })
    );
  }
}
