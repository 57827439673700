import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractEntityGridColumnComponent } from '@entity-framework/entity-grid/state/abstract-entity-grid-column.component';
import { BureauPaymentListItem } from '@app/bureau/payments/models/bureau-payment-list-item';

@Component({
  selector: 'app-bureau-payments-hrmc-submission-column',
  template: `
    <div>
      @if (listItem.hasHmrcSubmission === null || listItem.hasHmrcSubmission === undefined) {
        <app-icon [color]="'ink-25'">horizontal_rule</app-icon>
      } @else {
        @if (listItem.hasHmrcSubmission) {
          <app-icon [color]="'system-green'">check_circle</app-icon>
        } @else {
          <app-icon [color]="'ink-100'">cancel</app-icon>
        }
      }
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BureauPaymentsHmrcSubmissionColumnComponent extends AbstractEntityGridColumnComponent<BureauPaymentListItem> {}
