import { AbstractDataset } from './abstract-dataset';

export class KeyValuePairDataset<TKey, TValue> extends AbstractDataset<KeyValuePair<TKey, TValue>, TKey> {
  constructor(items: KeyValuePair<TKey, TValue>[]) {
    super(items);
  }

  getValue(item: KeyValuePair<TKey, TValue>): TKey {
    return item.key;
  }

  getLabel(item: KeyValuePair<TKey, TValue>): string {
    return String(item.value);
  }
}

export interface KeyValuePair<TKey, TValue> {
  key: TKey;
  value: TValue;
}
