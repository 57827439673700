import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-no-connection',
  templateUrl: './no-connection-banner.component.html',
  styleUrls: ['./no-connection-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NoConnectionBannerComponent {
  reload = () => window.location.reload();
}
