import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractEntityGridColumnComponent } from '@entity-framework/entity-grid/state/abstract-entity-grid-column.component';

@Component({
  template: `<app-compact-values-label [values]="getTeamNames()" [maxItems]="maxTeams"></app-compact-values-label>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BureauPaymentsTeamNameGridColumnComponent extends AbstractEntityGridColumnComponent<{ teams: string[] }> {
  maxTeams = 2;

  getTeamNames() {
    return this.listItem.teams ? this.listItem.teams.sort() : [];
  }
}
